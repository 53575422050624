import React, { Component } from 'react';
import { Link } from 'react-router-dom';


class ProductSliderV2 extends Component {

	render() {

		let publicUrl = process.env.PUBLIC_URL + '/'

		return <div className="ltn__search-by-place-area before-bg-top bg-image-top--- pt-115 pb-70" data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}>
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title-area ltn__section-title-2--- text-center---">
							<h6 className="section-subtitle section-subtitle-2--- ltn__secondary-color">Area Properties</h6>
							<h1 className="section-title">Find Your Dream House <br />
								Search By Area</h1>
						</div>
					</div>
				</div>
				<div className="row ltn__search-by-place-slider-1-active slick-arrow-1 go-top">
					<div className="col-lg-4">
						<div className="ltn__search-by-place-item">
							<div className="search-by-place-img">
								<Link to="/product-details"><img src={publicUrl + "assets/img/product-3/1-Ambrosia.jpg"} alt="#" /></Link>
								<div className="search-by-place-badge">
									<ul>
										<li>2 Properties</li>
									</ul>
								</div>
							</div>
							<div className="search-by-place-info">
								<h6><Link to="/contact">Vaishno Devi Circle</Link></h6>
								<h4><Link to="/product-details">Ambrosia</Link></h4>
								<div className="search-by-place-btn">
									<Link to="/product-details">View Property <i className="flaticon-right-arrow" /></Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="ltn__search-by-place-item">
							<div className="search-by-place-img">
								<Link to="/product-details"><img src={publicUrl + "assets/img/product-3/2-Aster.jpg"} alt="#" /></Link>
								<div className="search-by-place-badge">
									<ul>
										<li>5 Properties</li>
									</ul>
								</div>
							</div>
							<div className="search-by-place-info">
								<h6><Link to="/contact">Adani</Link></h6>
								<h4><Link to="/product-details">Aster</Link></h4>
								<div className="search-by-place-btn">
									<Link to="/product-details">View Property <i className="flaticon-right-arrow" /></Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="ltn__search-by-place-item">
							<div className="search-by-place-img">
								<Link to="/product-details"><img src={publicUrl + "assets/img/product-3/3-Parijat.jpg"} alt="#" /></Link>
								<div className="search-by-place-badge">
									<ul>
										<li>9 Properties</li>
									</ul>
								</div>
							</div>
							<div className="search-by-place-info">
								<h6><Link to="/contact">South Bopal</Link></h6>
								<h4><Link to="/product-details">Parijat</Link></h4>
								<div className="search-by-place-btn">
									<Link to="/product-details">View Property <i className="flaticon-right-arrow" /></Link>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="ltn__search-by-place-item">
							<div className="search-by-place-img">
								<Link to="/product-details"><img src={publicUrl + "assets/img/product-3/4-ShilpResidency.jpg"} alt="#" /></Link>
								<div className="search-by-place-badge">
									<ul>
										<li>5 Properties</li>
									</ul>
								</div>
							</div>
							<div className="search-by-place-info">
								<h6><Link to="/contact">Gota</Link></h6>
								<h4><Link to="/product-details">Shilp Residency</Link></h4>
								<div className="search-by-place-btn">
									<Link to="/product-details">View Property <i className="flaticon-right-arrow" /></Link>
								</div>
							</div>
						</div>
					</div>
					{/*  */}
				</div>
			</div>
		</div>
	}
}

export default ProductSliderV2